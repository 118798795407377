export function handleLeave (options, prefixes, element, shine) {
    if (options.shadow) {
        element.style[prefixes.boxShadow] = '0px 2.2px 2.2px rgba(0, 0, 0, 0.02), 0px 5.3px 5.3px rgba(0, 0, 0, 0.028), 0px 10px 10px rgba(0, 0, 0, 0.035), 0px 17.9px 17.9px rgba(0, 0, 0, 0.042), 0px 33.4px 33.4px rgba(0, 0, 0, 0.05), 0px 80px 80px rgba(0, 0, 0, 0.07)';
    }

    element.style[prefixes.transform] = 'rotateX(0deg) rotateY(0deg)';

    if (options.shine) {
        shine.style.opacity = 0;
    }
}