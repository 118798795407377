import { hoverManager } from './utils/hoverManager';
import { scrollManager } from './utils/scrollManager';

scrollManager('.hero__blur');

hoverManager({
    targets: '.apps__container',
    perspective: 1000,
    sensitivity: 12,
    invert: false,
    scale: 1.1,
    shadow: true,
    shine: true,
    touch: true
});