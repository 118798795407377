export function handleMove (options, prefixes, element, parentElement, sensitivity, shine, event) {
    let parentElementWidth = parentElement.offsetWidth;
    let parentElementHeight = parentElement.offsetHeight;
    let ox;
    let oy;

    if (event.type === 'touchmove') {
        event.preventDefault();
        let rect = element.getBoundingClientRect();
        ox = event.touches[0].clientX - rect.left;
        oy = event.touches[0].clientY - rect.top;
    } else {
        ox = event.offsetX;
        oy = event.offsetY;
    }

    let ax = options.invert ? -((parentElementWidth / 2) - ox) / sensitivity : ((parentElementWidth / 2) - ox) / sensitivity;
    let ay = options.invert ? ((parentElementHeight / 2) - oy) / sensitivity : -((parentElementHeight / 2) - oy) / sensitivity;
    let dy = oy - (parentElementHeight / 2);
    let dx = ox - (parentElementWidth / 2);
    let theta = Math.atan2(dy, dx);
    let ang = (theta * (180 / Math.PI)) - 90;
    let angle = ang < 0 ? ang + 360 : ang;

    if ('scale' in options) {
        if (options.scale) {
            element.style[prefixes.transform] = `rotateY(${ax}deg) rotateX(${ay}deg) scale3d(${options.scale}, ${options.scale}, ${options.scale})`;
        } else {
            element.style[prefixes.transform] = `rotateY(${ax}deg) rotateX(${ay}deg)`;
        }
    }

    if (options.shine) {
        shine.style.opacity = 1;
        shine.style.backgroundImage = `linear-gradient(${angle}deg, rgba(230, 230, 230, ${(oy / parentElementHeight) * 0.5}) 0%, transparent 80%)`;
    }
}