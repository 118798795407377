import Prefix from 'prefix';
import { handleEnter } from '../events/enter';
import { handleLeave } from '../events/leave';
import { handleMove } from '../events/move';

export function hoverManager (inputOptions = {}) {
    const defaultOptions = {
        targets: '.apps__container',
        perspective: 1000,
        sensitivity: 10,
        invert: false,
        scale: 0,
        shadow: false,
        shine: false,
        touch: false
    }

    const options = Object.assign({}, defaultOptions, inputOptions);

    const prefixes = {
        backfaceVisibility: Prefix('backfaceVisibility'),
        boxShadow: Prefix('boxShadow'),
        perspective: Prefix('perspective'),
        transformStyle: Prefix('transformStyle'),
        transform: Prefix('transform'),
        transitionDuration: Prefix('transitionDuration'),
        transitionProperty: Prefix('transitionProperty'),
        transitionTiming: Prefix('transitionTimingFunction'),
        userSelect: Prefix('userSelect'),
        willChange: Prefix('willChange')
    };

    const targets = document.querySelectorAll(options.targets);

    if (targets.length > 0) {
        targets.forEach(target => {
            let targetParent = target.parentNode;
            let shine;
            let sensitivity = 0;

            const perspectiveValue = (options.perspective && typeof options.perspective === 'number') ? options.perspective + 'px' : '1000px';
            targetParent.style[prefixes.perspective] = perspectiveValue;
            target.style[prefixes.perspective] = perspectiveValue;
            targetParent.style[prefixes.transformStyle] = 'preserve-3d';
            targetParent.style[prefixes.userSelect] = 'none';
            target.style[prefixes.transform] = 'rotateY(0deg) rotateX(0deg)';
            target.style[prefixes.transformStyle] = 'preserve-3d';
            target.style[prefixes.userSelect] = 'none';

            sensitivity = (options.sensitivity && typeof options.sensitivity === 'number') ? options.sensitivity : 20;

            options.touch && (target.style[prefixes.backfaceVisibility] = 'hidden');

            target.style.position = 'relative';
            target.style[prefixes.transitionDuration] = '0.2s';
            target.style[prefixes.transitionProperty] = options.shadow ? 'transform, box-shadow' : 'transform';
            target.style[prefixes.transitionTiming] = 'cubic-bezier(0.3, 1, 0.2, 1)';
            target.style[prefixes.willChangePrefix] = options.shadow ? 'transform, box-shadow' : 'transform';

            options.shadow && (target.style[prefixes.boxShadow] = '0px 2.2px 2.2px rgba(0, 0, 0, 0.02), 0px 5.3px 5.3px rgba(0, 0, 0, 0.028), 0px 10px 10px rgba(0, 0, 0, 0.035), 0px 17.9px 17.9px rgba(0, 0, 0, 0.042), 0px 33.4px 33.4px rgba(0, 0, 0, 0.05), 0px 80px 80px rgba(0, 0, 0, 0.07)');

            if (options.shine) {
                shine = document.createElement('div');
                shine.className = 'apps__item__shine';
                shine.style.inset = 0;
                shine.style.opacity = 0;
                shine.style.position = 'absolute';
                shine.style.zIndex = 9;
                shine.style[prefixes.transitionDuration] = '0.2s';
                shine.style[prefixes.transitionProperty] = 'opacity';
                shine.style[prefixes.transitionTiming] = 'cubic-bezier(0.3, 1, 0.2, 1)';
                shine.style[prefixes.willChange] = 'opacity';
                target.appendChild(shine);
            }

            targetParent.addEventListener('mouseenter', function () {
                handleEnter(options, prefixes, target);
            });

            targetParent.addEventListener('mousemove', function (event) {
                handleMove(options, prefixes, target, targetParent, sensitivity, shine, event);
            });

            targetParent.addEventListener('mouseleave', function () {
                handleLeave(options, prefixes, target, shine);
            });

            if (options.touch) {
                targetParent.addEventListener('touchstart', function () {
                    handleEnter(options, prefixes, target);
                });

                targetParent.addEventListener('touchmove', function (event) {
                    handleMove(options, prefixes, target, targetParent, sensitivity, shine, event);
                });

                targetParent.addEventListener('touchend', function () {
                    handleLeave(options, prefixes, target, shine);
                });
            }
        });
    }
}